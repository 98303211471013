import { createRouter, createWebHistory } from 'vue-router';

import lib from '@/lib';

import Layout from '@/layouts/Layout.vue';

import Home from '@/pages/Home.vue';
import Register from '@/pages/Register.vue';
import Profile from '@/pages/Profile.vue';
import Achievements from '@/pages/Achievements.vue';
import Hof from '@/pages/Hof.vue';
import Admin from '@/pages/Admin.vue';

import Test from '@/pages/Test.vue';

import PageNotFound from '@/pages/PageNotFound.vue';

const routes = [
	/*{
		path: '/',
		component: StartGame,
		props: {default: true, namespace: ["page", "StartGame"]}
	},
	*/
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '',
				component: Home,
			},
			{
				path: '/register',
				component: Register,
				meta: {
					guest: true,
				},
			},
			{
				path: '/profile',
				component: Profile,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/hof',
				component: Hof,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/achievements',
				component: Achievements,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/admin',
				component: Admin,
				meta: {
					requiresAuth: true,
					is_admin: true,
				},
			},
		],
	},
	{
		path: '/test',
		component: Test,
	},
	{
		path: '/logout',
		beforeEnter(to, from, next){
			storeInstance.state.app.auth.doLogout().then(() => {
				next({path: '/'});
			});
		},
	},
	{
		path: '/:pathMatch(.*)*',
		component: Layout,
		children: [
			{
				path: '',
				component: PageNotFound,
			},
		],
	},
	{
		path: '/:pathMatch(.*)',
		component: Layout,
		children: [
			{
				path: '',
				component: PageNotFound,
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	storeInstance.state.app.auth.doCheckAuth().then(() => {
		if(to.matched.some(record => record.meta.requiresAuth)){
			if(!storeInstance.state.app.auth.isAuth()){
				next({
					//path: '/login',
					path: '/',
					params: {
						nextUrl: to.fullPath,
					},
				})
			} else {
				if(to.matched.some(record => record.meta.is_admin)){
					if(storeInstance.state.app.auth.isAdmin()){
						next()
					} else {
						next('/')
					}
				} else {
					next()
				}
			}
		} else if(to.matched.some(record => record.meta.guest)){
			if(storeInstance.state.app.auth.isAuth()){
				next('/')
			} else {
				next()
			}
		} else {
			next() 
		}
	});
});
/*// Не позволяет авторизованному пользователю перейти на страницу логина и перекидывает его на главную страницу
router.beforeEach((to, from, next) => {
	if (to.path == '/login' && storeInstance.state.app.auth.isAuth()) next({path: '/'})
  	else next()
})*/

export default router;