<template>
	<main class="flex-shrink-0">
		<nav class="navbar navbar-dark bg-dark navbar-expand-lg">
			<div class="container">
				<router-link custom :to="{path: '/'}" v-slot="{href, route, navigate, isActive, isExactActive}">
					<a :href="href" @click="navigate" class="navbar-brand">
						<img src="//iwstatic.g.bsrv.su/img/panel/islandswars-lite-logo.svg" class="navbar-logo" title="Войны Островов" />
						
						<!--<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 953 953" width="32" height="32" version="1.1" shape-rendering="geometricPrecision" fill-rule="evenodd" clip-rule="evenodd" focusable="false" role="img" class="d-block" data-v-3d51269b=""><title data-v-3d51269b="">Войны Островов</title> <path fill="currentColor" d="M92 0h769c50 0 92 42 92 92v769c0 50-42 92-92 92H92c-50 0-92-42-92-92V92C0 42 42 0 92 0zm216 710c100 0 160-50 160-133 0-62-44-107-108-113v-3c48-8 86-52 86-102 0-71-55-117-140-117H111v468h197zM195 307h90c50 0 78 23 78 64 0 44-33 68-91 68h-77V307zm0 338V499h90c64 0 98 25 98 73s-33 73-94 73h-94zm503 65l163-468h-90L652 621h-2L531 242h-92l163 468h96z" data-v-3d51269b=""></path></svg>-->
					</a>
				</router-link>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#nav-collapse" aria-controls="nav-collapse" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div id="nav-collapse" class="navbar-collapse collapse">
					<ul class="navbar-nav me-auto mb-2 mb-lg-0">
						<!--
						<li class="nav-item dropdown" v-if="authModel.isAuth() && authModel.isAdmin()">
							<a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">GAME</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" href="https://iwgame.g.bsrv.su/island">Остров</a>
								<a class="dropdown-item" href="https://iwgame.g.bsrv.su/map">Карта мира</a>
								<div class="dropdown-divider"></div>
							</div>
						</li>
						-->
						<router-link custom :to="{path: '/test'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<li class="nav-item">
								<a :href="href" @click="navigate" class="nav-link" :class="[{'active': isExactActive}]">Test</a>
							</li>
						</router-link>
					</ul>
					<ul class="navbar-nav d-flex">
						<li class="nav-item dropdown" v-if="authModel.isAuth()">
							<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<em>{{authModel.userProfile.login}}</em>
							</a>
							<div class="dropdown-menu dropdown-menu-right">
								<router-link custom :to="{path: '/admin'}" v-slot="{href, route, navigate, isActive, isExactActive}" v-if="authModel.isAuth() && authModel.isAdmin()">
									<a :href="href" @click="navigate" class="dropdown-item" :class="[{'active': isExactActive}]">Админ-панель</a>
								</router-link>
								<div class="dropdown-divider" v-if="authModel.isAuth() && authModel.isAdmin()"></div>
								<router-link custom :to="{path: '/'}" v-slot="{href, route, navigate, isActive, isExactActive}">
									<a :href="href" @click="navigate" class="dropdown-item" :class="[{'active': isExactActive}]">Игровые миры</a>
								</router-link>
								<router-link custom :to="{path: '/hof'}" v-slot="{href, route, navigate, isActive, isExactActive}">
									<a :href="href" @click="navigate" class="dropdown-item" :class="[{'active': isExactActive}]">Зал славы</a>
								</router-link>
								<router-link custom :to="{path: '/achievements'}" v-slot="{href, route, navigate, isActive, isExactActive}">
									<a :href="href" @click="navigate" class="dropdown-item" :class="[{'active': isExactActive}]">Достижения</a>
								</router-link>
								<router-link custom :to="{path: '/profile'}" v-slot="{href, route, navigate, isActive, isExactActive}">
									<a :href="href" @click="navigate" class="dropdown-item" :class="[{'active': isExactActive}]">Профиль</a>
								</router-link>
								<div class="dropdown-divider"></div>
								<router-link custom :to="{path: '/logout'}" v-slot="{href, route, navigate, isActive, isExactActive}">
									<a :href="href" @click="navigate" class="dropdown-item" :class="[{'active': isExactActive}]">Выйти</a>
								</router-link>
							</div>
						</li>
						<li class="nav-item">
							<a href="jabascript://" @click="onAuthBylex()" class="nav-link" v-if="!authModel.isAuth()">Войти</a>
						</li>
						<router-link custom :to="{path: '/register'}" v-slot="{href, route, navigate, isActive, isExactActive}" v-if="!authModel.isAuth() && $router.currentRoute.value.path != '/'">
							<li class="nav-item">
								<a :href="href" @click="navigate" class="nav-link" :class="[{'active': isExactActive}]">Начать игру</a>
							</li>
						</router-link>
					</ul>
				</div>
			</div>
		</nav>
		<div>
			<router-view/>
		</div>
	</main>
	<footer class="footer mt-auto py-3 bg-dark">
		<div class="container text-center">
			<div class="row">
				<div class="col-md-12">
					<div class="mobile-badges">
						<a href="javascript://" class="mobile-badge googleplay"></a>
						<a href="javascript://" class="mobile-badge appstore"></a>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<a href="https://games.platform.bsrv.su" target="_blank" class="bylexgames"></a>
					<a class="ico18"></a>
					<div class="footer-menu">
						<router-link to="/test">Test</router-link>
						<a href="https://id.bylex.su/info/privacy" target="_blank">Политика конфиденциальности</a>
					</div>
					<div class="copyright text-white">&copy; byLex Games 2018-2023. Все права защищены.</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<style lang="css">
body {
	margin-top: 58px;
}

.navbar-logo {
	width: 32px;
	height: 32px;
}

.mobile-badges .mobile-badge {
	width: 150px;
	height: 54px;
	margin: 0 5px;
	border: 5px solid rgba(255, 255, 255, 0.5);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	display: inline-block;
	background-size: cover;
	background-repeat: no-repeat;
}
.mobile-badges .mobile-badge.googleplay {
	background-image: url('//iwstatic.g.bsrv.su/img/panel/googleplay-comingsoon-badge-ru.svg');
}
.mobile-badges .mobile-badge.appstore {
	background-image: url('//iwstatic.g.bsrv.su/img/panel/appstore-comingsoon-badge-ru.svg');
}

footer .bylexgames {
	margin-top: 5px;
	width: 60px;
	height: 60px;
	background-image: url(//iwstatic.g.bsrv.su/img/panel/bylexgames-logo.png);
	float: left;
	margin-left: 5px;
}
footer .ico18 {
	width: 65px;
	height: 65px;
	background-image: url(//iwstatic.g.bsrv.su/img/panel/ico18.png);
	float: right;
}
footer .footer-menu a {
	margin: 0 10px;
	text-decoration: underline;
	color: #fff;
}
footer .footer-menu a:hover {
	text-decoration: none;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import { Bylex } from '@/assets/plugins/socials';

//import QuickLinks from '@/components/QuickLinks.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'IW'});
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {
		async onAuthBylex(){
			await this.$Oauth.authenticate('bylex', Bylex).then(async (response) => {
				this.authModel.authChecked = false;
				await this.authModel.doCheckAuth().then(() => {
					this.$router.push('/');
				});
			}).catch((err) => {
				console.log(err);
			});
		},
	},
	created(){
		this.$http.interceptors.response.use(undefined, function(err){
			return new Promise(function(resolve, reject){
				if(err.status === 401 && err.config && !err.config.__isRetryRequest){
					this.authModel.doLogout();
				}
				throw err;
			});
		});
	},
	components: {
		//QuickLinks,
	},
}
</script>
