import { createApp } from 'vue/dist/vue.esm-bundler';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';
import axios, { AxiosInstance } from 'axios';
import VueAxios from 'vue-axios';
import { UniversalSocialauth } from 'universal-social-auth';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { FontAwesomeIcon } from '@/assets/plugins/font-awesome';
import Toaster from '@meforma/vue-toaster';
import { vfmPlugin } from 'vue-final-modal';
import app_mixin from "./mixin.js";
import lib from '@/lib';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import '@/assets/js/date.format.js';
import '@/main.scss';

const options = {
	providers: {
		bylex: {
			clientId: '',
			redirectUri: '',
		},
	}
}
const Oauth = new UniversalSocialauth(axios, options);

const app = createApp(App);
const metaManager = createMetaManager();

window['appInstance'] = null;
window['appComponent'] = null;
window['storeInstance'] = null;

app.config.globalProperties.$Oauth = Oauth;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$store = store;
//app.config.productionTip = false;
//app.config.debug = true;
//app.config.devtools = true;
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(store.state.app.i18n);
app.use(metaManager);
app.use(Toaster);
app.use(vfmPlugin);
app.component('font-awesome-icon', FontAwesomeIcon);
lib.addMixin(app_mixin);
app.mount('#app');

window['lib'] = lib;
window['axios'] = axios;

window['appInstance'] = app;
window['storeInstance'] = store;
//window['routerInstance'] = router;

// Запускаем скрипт проверки авторизации
setTimeout(() => {
	//storeInstance.state.auth.SSI_step1();
}, 100);
