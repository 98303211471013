<template>
	<div class="container mt-5">
		
		<div class="list-group">
			<a href="javascript://" class="list-group-item list-group-item-action" @click="onIsMobile()">Is Mobile</a>
			<a href="javascript://" class="list-group-item list-group-item-action" @click="onPlatform()">Platform</a>
			<a href="javascript://" class="list-group-item list-group-item-action" @click="onVibrate()">Vibrate</a>
			<a href="javascript://" class="list-group-item list-group-item-action" @click="onToast()">Toast</a>
			<a href="javascript://" class="list-group-item list-group-item-action" @click="onQr()">QR Scan</a>
			<a href="javascript://" class="list-group-item list-group-item-action" @click="onQrMass()">QR Mass Scan</a>
			<a href="javascript://" class="list-group-item list-group-item-action" @click="onSpeechToText()">Speech To Text</a>
			<a href="javascript://" class="list-group-item list-group-item-action" @click="onGetContactsList()">Get Contacts List</a>
			<a href="javascript://" class="list-group-item list-group-item-action" @click="onMakeNotification()">Make Notification</a>
			<a href="javascript://" class="list-group-item list-group-item-action" @click="appModel.pageReload()">page reload</a>
		</div>
		<div class="list-group mt-5">
			<div class="list-group-item" v-for="contact in appModel.contacts">{{contact.name}}: {{contact.number}}</div>
			<!--<div class="list-group-item">{{appModel.contacts}}</div>-->
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Test | IW'});
	},
	data: () => ({
		contacts: '',
    }),
	computed: {},
	methods: {
		onIsMobile(){
			alert(lib.mobile.isMobile() ? 'true' : 'false');
		},
		onPlatform(){
			alert(lib.mobile.getPlatform() + ', v.' + lib.mobile.getVersion());
		},
		onVibrate(){
			lib.mobile.vibrate();
		},
		onToast(){
			lib.mobile.showToast('toast text');
		},
		onQr(){
			lib.mobile.callQRScanner({
				callback(decodeText){
					window['lib'].mobile.showToast(decodeText);
				},
				autoCloseCamera: 1,
				autoResumeCamera: 0,
				sound: 0,
				vibration: 0,
			});
		},
		onQrMass(){
			lib.mobile.callQRScanner({
				callback(decodeText){
					window['lib'].mobile.showToast(decodeText);
				},
				autoCloseCamera: 0,
				autoResumeCamera: 1,
				sound: 0,
				vibration: 0,
			});
		},
		onSpeechToText(){
			lib.mobile.speechToText({
				callback(decodeText){
					window['lib'].mobile.showToast(decodeText);
				},
			});
		},
		onGetContactsList(){
			lib.mobile.getContactsList({
				callback(contacts){
					window['storeInstance'].state.app.setContacts(contacts);
				},
			});
		},
		onMakeNotification(){
			lib.mobile.makeNotification('test title', 'test text', this.appModel.project_url + prompt('Enter postfix', '/'));
		},
	},
	created(){},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
}
</script>